<template>
  <div class="articleList">
    <a-card :bordered="false" class="gmb-10">
      <div class="g-flex gpv-10 gph-10 g-flex gfs-14">
        <div class="gpv-5 gph-5" style="width:53px">项目：</div>
        <div class="g-flex g-wrap g-flex-1">
          <div class="gpv-5 gph-5 g-pointer gmr-10" :class="{active:param.subject_type==''}" @click="changeSubjectType('')">
            全部
          </div>
          <div v-for="(item,index) in subjectLeafList" :key="index" class="gpv-5 gph-5 g-pointer gmr-10" :class="{active:param.subject_type==item.value}" @click="changeSubjectType(item.value)">
            {{item.label}}
          </div>
        </div>
      </div>
      <div class="g-flex gpv-10 gph-10 g-flex gfs-14">
        <div class="gpv-5 gph-5" style="width:53px">题型：</div>
        <div class="g-flex g-wrap g-flex-1">
          <div v-for="(item,index) in typeList" :key="index" class="gpv-5 gph-5 g-pointer gmr-10" :class="{active:param.question_type_id==item.id}" @click="changeType(item)">
            {{item.name}}
          </div>
        </div>
      </div>
      <div class="g-flex gpv-10 gph-10 g-flex gfs-14">
        <div class="gpv-5 gph-5" style="width:53px">分类：</div>
        <div class="g-flex g-wrap g-flex-1">
          <div v-for="(item,index) in sortList" :key="index" class="gpv-5 gph-5 g-pointer gmr-10" :class="{active:param.classify_id==item.id}" @click="changeSort(item)">
            {{item.name}}
          </div>
        </div>
      </div>
    </a-card>
    <a-card :bordered="false">
      <div class="g-flex g-wrap" v-if="tableData.length>0">
        <div @click="toDetail(item)" class="gw-400p gmr-20 gmb-20 gbr-4 gbd gpv-10 gph-10 g-pointer" v-for="(item,index) in tableData" :key="index">
          <div class="g-flex g-justify-space-between gmb-10">
            <div>
              <span>{{subjectList[item.subject_type-1]}}</span>
              <span>·{{item.question_type_name}}</span>
              <span>·{{item.classify_name}}</span>
            </div>
            <div class="gc-aaa">{{item.update_date}}</div>
          </div>
          <div class="g-flex g-justify-space-between">
            <div class="g-flex-1 g-flex g-flex-column g-justify-space-between gmr-10">
              <div class="gfw-b gfs-18">{{item.name}}</div>
              <div>
                <span @click.stop="changeCollectStatus(item,1)" class="iconfont icon-noCollect g-pointer" style="font-size:18px" v-if="!item.is_collect"></span>
                <span @click.stop="changeCollectStatus(item,0)" class="iconfont icon-collect gc-red g-pointer" style="font-size:18px" v-else></span>
              </div>
            </div>
            <img v-if="item.cover_img" :src="qiniuDomain+item.cover_img" class="gw-120p gh-120p">
            <img v-else src="@/assets/imgs/img4.png" class="gw-120p gh-120p">
          </div>
        </div>
      </div>
      <div v-else class="g-flex g-flex-c">
        <a-empty />
      </div>
      <div class="gtr">
        <a-pagination v-model="param.page" :total="total" show-less-items @change="getData" />
      </div>
    </a-card>
  </div>
</template>
<script>
import {
  postImportantPage,
  putCollectArticle,
  getArticleTypeList,
  getArticleSortList,
} from "@/api/articlePaper";
import { subjectList, subjectLeafList } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      subjectList: JSON.parse(JSON.stringify(subjectList)),
      subjectLeafList: JSON.parse(JSON.stringify(subjectLeafList)),
      param: {
        subject_type: "",
        question_type_id: "",
        classify_id: "",
        important_word_name: "",
        page: 1,
        limit: 10,
      },
      tableData: [],
      total: 0,
      typeList: [],
      sortList: [],
    };
  },
  computed: {
    ...mapGetters(["qiniuDomain", "userInfo"]),
  },
  created() {
    this.getArticleTypeList();
    this.getArticleSortList();
    this.getData();
  },
  methods: {
    //题型列表
    getArticleTypeList() {
      getArticleTypeList({
        // subject_type: this.param.subject_type,
        campus_id: this.userInfo.campus_id,
      }).then((res) => {
        if (res.code == 200) {
          this.typeList = [
            {
              id: "",
              name: "全部",
            },
            ...res.data,
          ];
        }
      });
    },
    //分类列表
    getArticleSortList() {
      getArticleSortList({
        // subject_type: this.param.subject_type,
        // question_type_id: this.param.question_type_id,
        campus_id: this.userInfo.campus_id,
      }).then((res) => {
        if (res.code == 200) {
          this.sortList = [
            {
              id: "",
              name: "全部",
            },
            ...res.data,
          ];
        }
      });
    },
    //项目过滤
    changeSubjectType(value) {
      this.param.subject_type = value;
      this.getData();
    },
    //题型过滤
    changeType(item) {
      this.param.question_type_id = item.id;
      this.getData();
    },
    //分类过滤
    changeSort(item) {
      this.param.classify_id = item.id;
      this.getData();
    },
    getData() {
      postImportantPage({
        ...this.param,
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    //收藏
    changeCollectStatus(item, status) {
      item.is_collect = status;
      putCollectArticle({
        important_word_id: item.id,
        is_collect: status,
      });
    },
    toDetail(item) {
      this.$router.push({
        path: "/article/detail",
        query: {
          paperId: this.id,
          id: item.id,
        },
      });
    },
  },
  watch: {
    // 'param.subject_type': {
    //   deep: true,
    //   handler(val) {
    //     this.param.question_type_id = ''
    //     this.typeList = []
    //     if (val) {
    //       this.getArticleTypeList()
    //     }
    //   },
    // },
    // 'param.question_type_id': {
    //   handler(val) {
    //     this.param.classify_id = undefined
    //     this.sortList = []
    //     if (val) {
    //       this.getArticleSortList()
    //     }
    //   },
    //   deep: true,
    // },
    // 'param.classify_id': {
    //   deep: true,
    //   handler() {
    //     this.param.page = 1
    //     this.getData()
    //   },
    // },
  },
};
</script>
<style lang="less" scoped>
.articleList {
  .active {
    color: #fff;
    background: #1890ff;
  }
}
</style>